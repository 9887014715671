// @flow strict
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';
import type { MarkdownRemark } from '../types';

type Props = {
  data: {
    markdownRemark: MarkdownRemark
  }
};

const AboutTemplate = ({ data }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const { html: pageBody } = data.markdownRemark;
  const { frontmatter } = data.markdownRemark;
  const { title: pageTitle, description: pageDescription, socialImage } = frontmatter;
  const metaDescription = pageDescription !== null ? pageDescription : siteSubtitle;

  return (
    <Layout title={`${pageTitle} - ${siteTitle}`} description={metaDescription} socialImage={socialImage} >
      <Sidebar />
      <Page title={pageTitle}>
        <div dangerouslySetInnerHTML={{ __html: pageBody }} />
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4308.275437768319!2d34.84854037976507!3d32.08800481189148!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6ae1c2203789f43f!2sZlil+Nails!5e0!3m2!1sen!2sil!4v1494185515641"
          width="100%" height="450" frameBorder="0" allowFullScreen />
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query AboutUs($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        description
        socialImage
      }
    }
  }
`;

export default AboutTemplate;
